import React from 'react';
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import crisis from '../images/crisis_cover_image.png'
import CTA from '../components/CTA'
import pdf from '../assets/WRALDSCovidEraMarketing202004-3.pdf'

export default () => (
  <div id="app">
    <Helmet>
        <meta charSet="utf-8" />
        <meta name="Description" content="We're offering great downloadable resources for your business. Learn more with WRAL Digital Solutions."></meta>
        <title>Marketing Downloads | WRAL Digital Solutions</title>
        <link rel="canonical" href="https://www.wraldigitalsolutions.com/downloads/" />
      </Helmet>
    <PreHeader/>
    <Header/>
    <h1 className="advertising--title">Downloads</h1>
    <div className="advertising">
      <div className = "advertising">
        <div className = "advertising_grid">

          <div className= "advertising_block">
            <img src={crisis} className="gtm--image" alt="GTM Conversion Setup | WRAL Digital Solutions"/>
          </div>
          <div className= "advertising_block">
            <h3 className="advertising_block--title-padding">Marketing During a Crisis</h3>
            <div className="business_cta">
                    <a href={pdf} target="_blank"><button type="button" id="download" className="cta_contact-us--desktop">Download PDF</button></a>
                    <a href={pdf} target="_blank"><button type="button" id="download" className="cta_contact-us--mobile">Download PDF</button></a>
            </div>
            <p className="advertising_block--title-contactus">Want to discuss ideas for your business?</p>
            <div className="hollow_cta">
                    <a href="/contact/"><button type="button" id="download" className="cta_contact-us--desktop">Contact Us Today</button></a>
                    <a href="/contact/"><button type="button" id="download" className="cta_contact-us--mobile">Contact Us Today</button></a>
            </div>

          </div>

          {/* Individual block sections for downloads */}

          {/* <div className= "advertising_block">
            <a href={pdf} target="_blank"><img src={crisis} className="gtm--image" alt="GTM Conversion Setup | WRAL Digital Solutions"/></a>
          </div>
          <div className= "advertising_block">
            <h3 className="advertising_block--title">Marketing During a Crisis</h3>
            <div className="business_cta">
                    <a href={pdf} target="_blank"><button type="button" className="cta_contact-us--desktop">Download PDF</button></a>
                    <a href={pdf} target="_blank"><button type="button" className="cta_contact-us--mobile">Download PDF</button></a>
                </div>
          </div> */}


        </div>
      </div>
    </div>
    <Footer/>
  </div>
);
